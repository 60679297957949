import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 15 min. `}<meta itemProp="prepTime" content="PT15M" /></h5>
    <h5>{`Cook time: 55 min. `}<meta itemProp="cookTime" content="PT55M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Large eggplant, 1</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Olive oil, 1/3 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 1 clove</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cumin, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Red pepper flakes, 1/4 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, a couple dashes to taste</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Grate garlic</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Preheat oven to 400F.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Combine olive oil, garlic, cumin, red pepper flakes, and salt in a small bowl.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Slice eggplant into 1-inch slices.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Dip each slice into the oil mixture to coat, and place onto a aluminum foil-lined baking sheet.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Roast for 30-40 minutes until it looks browned, soft in the middle, and crisp.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      